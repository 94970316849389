html {
    scroll-behavior: smooth;
}

.sidenav-div{
    height: 100%;
}

.header-avatar{
    vertical-align: middle;
}
.topnav-header{
    position: sticky;
    top: 0;
    z-index: 1000;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #FFFFFF;
    border-bottom: 1px solid rgba(5, 5, 5, 0.06);
}

.topnav-logo{
    color: #000000;
    /* font-size: 35px; */
}

.topnav-logo-u{
    color: #FFA500;
}

.layout-content{
    margin: 10px;
    padding: 20px;
    background-color: #FFFFFF;
    height: auto;
}

/* .ant-menu-horizontal .ant-menu-item:hover::after,
.ant-menu-horizontal .ant-menu-item-selected::after {
  border-bottom: none !important;
} */